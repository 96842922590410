import React from 'react'
import SEO from '../components/App/SEO' /* eslint-disable */
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from 'react-accessible-accordion'
import Layout from '../components/App/Layout'
import Navbar from '../components/App/Navbar'
import PageBanner from '../components/Common/PageBanner'
import Footer from '../components/App/Footer'
// import StartProject from '../components/Common/StartProject'

const FAQ = () => {
  return (
    <Layout>
      <SEO title='FAQ' />
      <Navbar />

      <PageBanner
        pageTitle='FAQ'
        homePageText='Home'
        homePageUrl='/'
        activePageText='FAQ'
      />

      <div className='ptb-20'>
        <div className='container'>
          <div className='faq-accordion'>
            <Accordion allowZeroExpanded preExpanded={['a']}>
              <AccordionItem uuid='a'>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Q1. What is ThermoEcos?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    ThermoEcos ecosystem is an open-source framework for
                    thermodynamic modeling and required data auch as standard
                    thermodynamic properties, experimental data, and recipes
                    (compositions) of relevant objects such as rock, seawater,
                    cement etc. It embraces ThermoHub and ThermoMod.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid='b'>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Q2. What is ThermoHub?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    ThermoHub is a multi-format database for storing various
                    types of thermodynamic data arranged in several
                    thermodynamic datasets and available online for everybody
                    with help of the ThermoFun client code.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid='c'>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Q3. What is ThermoMod?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    ThermoMod is part of ThermoEcos embracing microservices and
                    codes for fetching, handling, optimization and prediction of
                    thermodynamic data, as well as codes for performing
                    (geo)chemical thermodynamic modelling using GEM algorithms.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid='d'>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Q4. How to use ThermoHub?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Most codes that work with ThermoHub have Python APIs and can
                    be used from Jupyter notebooks. An interactive demo is also
                    available (see "ThermoFun Demo" button in the main page).
                    The ThermoFun code, publicly available on Github, is already
                    used by modelling codes such as xGEMS/pyGEMS and Reaktoro.
                    Please, contact the Team for uploading your thermodynamic
                    dataset to the ThermoHub database to make it available for all.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid='e'>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Q5. How to use ThermoEcos for modelling?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Currently, you can download and install GEM-Selektor or
                    Reaktoro modelling codes that both can pull thermodynamic data
                    from central ThermoHub database and store locally for fast
                    calculations. In perspective, it will be possible to use
                    parts of these codes in Jupyter notebooks or Python codes.
                    Dedicated web apps will be available as well. GEMSFITS
                    parameter optimization code, currently available for
                    download, will be replaced by a more versatile ThermoFit
                    service, integrated with both ThermoHub and ThermoExp
                    databases. More database clients will also be available.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>

      {/* <StartProject /> */}

      <Footer />
    </Layout>
  )
}

export default FAQ
